<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formExport">
      <b-form>
        <b-row>
          <!-- Exercício  -->
          <b-col md="3">
            <b-form-group label="Exercício" label-for="exercicio">
              <validation-provider #default="{ errors }" name="Exercício" rules="required">
                <b-form-select name="exercicio" v-model="items.exercicio" :options="exercicioOpcoes"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Competência  -->
          <b-col md="3">
            <b-form-group label="Competência" label-for="competencia">
              <validation-provider #default="{ errors }" name="Competência" rules="required">
                <b-form-select name="competencia" v-model="items.competencia" :options="competenciaOpcoes"> </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 25px; width: 160px"
              @click.prevent="authESalve"
            >
              {{ !isBusy ? 'Gerar Arquivo' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </b-card>
</template>

<script>
  //validacao
  import useJwt from '@/auth/jwt/useJwt'
  import { isLetter } from '@/libs/utils'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { required } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      AutenticaSenha,
      NaoAutorizadoModal,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        items: {},
        isLetter,
        pesquisar: { perPage: 25, currentPage: 1 },
        exercicioOpcoes: [
          { value: '', text: 'Selecione' },
          { value: '2022', text: '2022' },
          { value: '2021', text: '2021' },
          { value: '2020', text: '2020' },
          { value: '2019', text: '2019' },
          { value: '2018', text: '2018' },
          { value: '2017', text: '2017' },
          { value: '2016', text: '2016' },
          { value: '2015', text: '2015' },
        ],
        competenciaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: '1', text: 'Janeiro' },
          { value: '2', text: 'Fevereiro' },
          { value: '3', text: 'Março' },
          { value: '4', text: 'Abril' },
          { value: '5', text: 'Maio' },
          { value: '6', text: 'Junho' },
          { value: '7', text: 'Julho' },
          { value: '8', text: 'Agosto' },
          { value: '9', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
        ],
        acesso: {},
      }
    },
    mounted() {},
    methods: {
      authESalve() {
        this.$refs.formExport.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.salvar()
          }
        }
      },
      salvar() {
        if (this.items.id == undefined) {
          const parametro = {
            ...this.items,
            institutoId: (this.items.institutoId = this.optionsLocal.id),
            txipca: this.items.txipca.replace(/[%]/g, ''),
            txacumulado: 0,
          }
          useJwt
            .post(`indices/cadastrarIndices`, parametro)
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((response) => {
                window.location.replace('configuracoes')
              })
            })
            .catch((error) => {
              console.error(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
            })
        } else {
          const parametro = {
            ...this.items,
            txipca: this.items.txipca.replace(/[%]/g, ''),
          }
          useJwt
            .put(`indices/putIndices`, this.items.id, parametro)
            .then((response) => {
              this.$swal({
                title: 'Atenção!',
                text: 'Registro alterado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then((response) => {
                this.editaMethod()
              })
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
    },
  }
</script>
